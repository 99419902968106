<!--
 * @Date: 2021-03-20 13:35:20
 * @LastEditors: frank
 * @LastEditTime: 2021-05-11 14:16:17
 * @FilePath: /shop_frontend/src/views/memberList/addUser.vue
 * @Description: Description
-->
<template>
  <section class="addPoints">
    <a-modal
      title="选择用户"
      :visible="visible"
      @ok="handleOk"
      @cancel="cancelDodal"
      width="50%"
    >
      <div>
        <a-table
          :columns="columns"
          :data-source="dataSource"
          :pagination="pagination"
          bordered
          @change="changeTable"
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }"
        >
        </a-table>
      </div>
    </a-modal>
  </section>
</template>

<script>
const columns = [
  {
    title: "ID",
    dataIndex: "id",
  },
  {
    title: "昵称",
    dataIndex: "name",
  },
  {
    title: "手机号",
    dataIndex: "phone",
  },
];
export default {
  name: "addPoints",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    currentRecord: {
      type: Object,
    },
  },
  data() {
    return {
      selectedRowKeys: [],
      columns,
      addForm: {},
      dataSource: [],
      pagination: {
        total: 0,
        showTotal: (total, range) => {
          return `共 ${total} 条`;
        },
        showQuickJumper: true,
        defaultPageSize: 15,
      },
      page: 1,
    };
  },
  components: {},
  computed: {
       params() {
      return {
        page: this.page,
        page_size: 15
      };
    },
  },
  watch: {
    visible(nVal) {
      if (nVal) {
        let index = this.dataSource.findIndex(
          (el) => el.id == this.currentRecord.sharer
        );
        if (index > -1) {
          this.selectedRowKeys = [index];
        } else {
          this.selectedRowKeys = [];
        }
      }
    },
  },
  created() {
    this.initTable();
  },
  mounted() {},
  methods: {
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys.slice(-1);

      console.log(this.selectedRowKeys, "this.selectedRowKeys");
    },
    changeTable(pagination) {
      this.page = pagination.current;
      this.initTable();
    },
    handleOk() {
      this.$axios
        .post(`/members/${this.currentRecord.id}/set_sharer/`, {
          sharer: this.dataSource[this.selectedRowKeys[0]].id,
        })
        .then(() => {
          this.$message.success("操作成功");
          this.$parent.userVisible = false;
          this.$parent.initTable();
        });
      this.$parent.userVisible = false;
    },
    cancelDodal() {
      this.$parent.userVisible = false;
    },
    initTable() {
      this.$axios.get("/members/", { params: this.params }).then((res) => {
        this.dataSource = res.results || [];
        this.pagination.total = res.count || 0;
      });
    },
  },
};
</script>

<style scoped lang="less">
</style>
